import { Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/button";
import { useProductCartContext } from "../../../providers/ProductCartContextProvider";
import "./ProductsTable.css";

export function ProductsTable() {
  const { t } = useTranslation();
  const { products, removeProduct, updateProductQuantity } =
    useProductCartContext();

  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("bundleEditor.select")}</TableHead>
              <TableHead>{t("bundleEditor.id")}</TableHead>
              <TableHead>{t("bundleEditor.prodName")}</TableHead>
              <TableHead className=" text-center">
                {t("bundleEditor.quantity")}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.productId}>
                <TableCell>
                  <Button
                    size="sm"
                    type="button"
                    variant="ghost"
                    onClick={() => {
                      removeProduct(product.productId);
                    }}
                  >
                    <Trash2 size="16" />
                  </Button>
                </TableCell>
                <TableCell>{product.productId}</TableCell>
                <TableCell>{product.name}</TableCell>

                <TableCell className="flex justify-center">
                  <div className="flex sm::flex-col">
                    <Button
                      size="sm"
                      className="mr-2"
                      type="button"
                      variant="outline"
                      onClick={() => updateProductQuantity(product, -1)}
                    >
                      -
                    </Button>
                    <div className="w-16 h-9 flex items-center justify-center	">
                      <label>{product.quantity || 0}</label>
                    </div>
                    <Button
                      size="sm"
                      className="ml-2"
                      type="button"
                      variant="outline"
                      onClick={() => updateProductQuantity(product, 1)}
                    >
                      +
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {!products.length && (
              <TableRow>
                <TableCell colSpan={4} className="h-24 text-center">
                  {t("bundleEditor.noSelected")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
