import useQuery from "src/hooks/useQuery";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "lucide-react";
import { ProductDTO, StoreDTO, VariantDTO } from "src/types/store.types";
import logo from "../../resources/logo.png";
import ErrorAlert from "../ErrorHandler/ErrorComponents/ErrorAlert";
import { ApiClient } from "src/types/ApiClient";
import { BundleDTO } from "src/types/bundle.types";
import { ApiError } from "src/types/errors.types";

interface InternalVariant {
  id: string;
  variant: VariantDTO;
}

const getStoreName = (store: StoreDTO): string =>
  (store?.name?.es || store?.name?.pt || store?.name?.en)!;

const getTotalProducts = (bundle: BundleDTO | undefined): number => {
  if (!bundle || !bundle.content || !bundle.content.products) {
    return 0;
  }
  return bundle.content.products
    .map((prod) => prod.quantity!)
    .reduce((prev, curr) => prev + curr, 0);
};

const ProductRow = ({
  product,
  storeId,
  apiClient,
  onVariantSelect,
}: {
  product: ProductDTO;
  storeId: string;
  apiClient: ApiClient;
  onVariantSelect: (variant: InternalVariant | undefined) => void;
}) => {
  const { t } = useTranslation();
  const [variants, setVariants] = useState<ProductDTO[] | undefined>(undefined);
  const [error, setError] = useState<ApiError | undefined>(undefined);

  useEffect(() => {
    const fetchVariants = async () => {
      const { products, error } = await apiClient.getPublicStoreProduct(
        storeId,
        "" + product.productId,
      );
      if (error) {
        setError(error);
        return;
      }
      setVariants(products);
    };

    if (product.productType === "MODEL") {
      void fetchVariants();
    }
  }, [apiClient, storeId, product]);

  const handleSelectChange = (event: any, modelId: number, idx: number) =>
    onVariantSelect({
      id: product.productId + "" + idx,
      variant: { productModelId: "" + modelId, selectedId: event.target.value },
    });

  if (error) {
    return <ErrorAlert message={"customerData.errorMessage"} />;
  }

  if (product.productType === "MODEL") {
    const rowsQty = product.quantity! === 1 ? 1 : product.quantity! + 1;

    return (
      <>
        {[...Array(rowsQty).keys()].map((idx) => {
          return (
            <tr
              key={product.productId + idx}
              className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
            >
              <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
                {idx === 0 || product?.quantity! === 1 ? product?.quantity : ""}
              </td>
              <td
                className={
                  idx === 0 || product?.quantity! === 1
                    ? undefined
                    : "text-gray-400"
                }
              >
                {idx === 0 || product?.quantity! === 1
                  ? product?.name
                  : product?.name + " #" + idx}
              </td>
              <td>
                {(idx !== 0 || product?.quantity! === 1) && (
                  <select
                    defaultValue={"default"}
                    onChange={(e) =>
                      handleSelectChange(e, product.productId, idx)
                    }
                  >
                    <option value={"default"} disabled hidden>
                      {t("customerData.select")}
                    </option>
                    {variants?.map((variant) => (
                      <option key={variant.productId} value={variant.productId}>
                        {variant.values?.map((v) => v?.es).join(" - ")}
                      </option>
                    ))}
                  </select>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  }
  return (
    <tr
      key={product.productId}
      className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
    >
      <td className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
        {product?.quantity}
      </td>
      <td>{product?.name}</td>
    </tr>
  );
};
export default function BundleViewPage({
  apiClient,
}: {
  apiClient: ApiClient;
}) {
  const query = useQuery();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [store, setStore] = useState<StoreDTO | undefined>(undefined);
  const [bundle, setBundle] = useState<BundleDTO | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [storeLogoUrl, setStoreLogoUrl] = useState<string | undefined>(
    undefined,
  );
  const [variants, setVariants] = useState<InternalVariant[] | undefined>(
    undefined,
  );

  const handleVariantSelect = (variant: InternalVariant | undefined) => {
    if (!variant) {
      return;
    }

    if (!variants) {
      setVariants([variant!]);
      return;
    }
    const newVariants: InternalVariant[] = variants.filter(
      (v) => v.id !== variant.id,
    );
    newVariants.push(variant);
    setVariants(newVariants);
  };

  const storeId: string = query.get("storeId")!;
  const slug: string = query.get("slug")!;

  useEffect(() => {
    const fetchStore = async () => {
      const { store, error } = await apiClient.getPublicStore(storeId);
      if (error || !store) {
        setErrorMessage(error?.detail);
        return;
      }
      setStore(store);
      if (store?.logoUrl !== "None") {
        setStoreLogoUrl(store?.logoUrl);
      }
    };

    void fetchStore();
  }, [apiClient, storeId]);

  useEffect(() => {
    const fetchBundle = async () => {
      const { bundle, error } = await apiClient.getPublicBundleContent(slug);
      if (error || !bundle) {
        setErrorMessage(error?.detail);
        return;
      }
      setBundle(bundle);
    };

    void fetchBundle();
  }, [apiClient, slug]);

  const storeName: string = getStoreName(store!);
  const variantQty: number = bundle?.content.products
    .filter((p) => p.productType === "MODEL")
    .map((p) => p.quantity)
    .reduce((prev, curr) => prev! + curr!, 0)!;
  const isSubmitDisabled =
    !name ||
    !surname ||
    !email ||
    !storeId ||
    !slug ||
    (variants?.length !== undefined && variants.length !== variantQty);

  const handleOrderCreation = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(undefined);

    const orderVariants: VariantDTO[] = (variants! || []).map((v) => v.variant);

    const { checkout, error } = await apiClient.createOrder(
      storeId,
      slug,
      name,
      surname,
      email,
      orderVariants,
    );
    if (error || !checkout) {
      setLoading(false);
      setErrorMessage(error?.detail);
      return;
    }
    window.location.replace(checkout.checkoutUrl);
  };

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-7xl md:w-2/4 mt-4 mx-2 p-4 rounded-md h-min w-full">
        {storeLogoUrl && (
          <>
            {loadingLogo && (
              <Store
                color="#a6a6a6"
                className="flex w-20 h-20 rounded-full mx-auto mb-2"
              />
            )}
            <div style={{ display: loadingLogo ? "none" : "block" }}>
              <img
                className="flex h-20 rounded-full mx-auto mb-2"
                src={storeLogoUrl!}
                alt={t("customerData.logoAlt")}
                onLoad={() => setLoadingLogo(false)}
              />
            </div>
          </>
        )}
        <h2 className=" mb-2 text-center text-gray-600">{storeName}</h2>
        {errorMessage === "BUNDLE_NOT_FOUND" && (
          <h1 className="text-xl text-center w-full font-semibold">
            {t("customerData.expired")}
          </h1>
        )}
        {errorMessage !== "BUNDLE_NOT_FOUND" && (
          <>
            <h1 className="text-xl w-full font-semibold">
              {t("customerData.title")}
            </h1>
            {bundle?.content.discount ? (
              <h2 className="mb-2 text-gray-600">
                {`${getTotalProducts(bundle)} ${t("customerData.productsWith")} `}
                <b className="text-primary">{`${bundle?.content.discount}% ${t("customerData.ofDiscount")}`}</b>
              </h2>
            ) : (
              <h2 className="mb-2 text-gray-600">
                {`${getTotalProducts(bundle)} ${t("customerData.products")} `}
              </h2>
            )}
            <h2 className="mb-2 text-gray-600">{t("customerData.desc")}</h2>
            <div className="border-2 p-6 mb-2">
              <Label>{t("customerData.products")}</Label>
              <table className="w-full caption-bottom text-sm">
                <tbody className="[&amp;_tr:last-child]:border-0">
                  {bundle?.content.products.map((product) => {
                    return (
                      <ProductRow
                        product={product}
                        storeId={storeId}
                        apiClient={apiClient}
                        onVariantSelect={handleVariantSelect}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
            <h2 className="text-l w-full font-semibold">
              {t("customerData.data")}
            </h2>
            {errorMessage && (
              <ErrorAlert message={"customerData.errorMessage"} />
            )}
            <form
              className="mx-auto w-full mt-1 border-2 p-6 mb-2"
              onSubmit={handleOrderCreation}
            >
              <div>
                <Label>{t("customerData.name")}</Label>
                <Input
                  className="my-2"
                  type="name"
                  placeholder={t("customerData.name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Label>{t("customerData.surname")}</Label>
                <Input
                  className="my-2"
                  type="surname"
                  placeholder={t("customerData.surname")}
                  onChange={(e) => setSurname(e.target.value)}
                />
                <Label>{t("customerData.email")}</Label>
                <Input
                  className="my-2"
                  type="email"
                  placeholder={t("customerData.email")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </form>
            <div className="w-full flex justify-center">
              <Button
                disabled={isSubmitDisabled || loading}
                type="submit"
                className="mt-3"
                onClick={handleOrderCreation}
              >
                {loading && (
                  <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
                )}
                {t("customerData.button")}
              </Button>
            </div>
          </>
        )}
        <img
          src={logo}
          alt="Bundle logo"
          width="80px"
          className="flex mx-auto mt-5 mb-3"
        />
      </div>
    </div>
  );
}
