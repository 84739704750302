import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { Button } from "../../ui/button";
import {
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { ProductDTO } from "src/types/store.types";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../ui/checkbox";
import { useProductCartContext } from "../../../providers/ProductCartContextProvider";
import { Pagination } from "@mui/material";
import { Input } from "src/components/ui/input";

export function SelectProductsModal({
  rowProducts,
  productsPageCount,
  handleSearch,
  handleProductsPageChange,
}: {
  rowProducts: ProductDTO[];
  productsPageCount: number;
  handleSearch: (search: string | null) => void;
  handleProductsPageChange: (newPage: number) => void;
}) {
  const { t } = useTranslation();
  const { updateProductQuantity } = useProductCartContext();

  const [bufferedSelectedProducts, setBufferedSelectedProducts] = useState<
    number[]
  >([]);
  const [ search, setSearch ] = useState<string | undefined>(undefined);
  const handleProductSelection = (productId: number, isChecked: boolean) => {
    if (isChecked) {
      setBufferedSelectedProducts([...bufferedSelectedProducts, productId]);
    } else {
      const filtered = bufferedSelectedProducts.filter(
        (id) => id !== productId,
      );
      setBufferedSelectedProducts(filtered);
    }
  };

  const handleConfirm = () => {
    for (const productId of bufferedSelectedProducts) {
      updateProductQuantity(
        rowProducts.find((prod) => prod.productId === productId)!,
        1,
      );
    }
    setBufferedSelectedProducts([]);
    setSearch(undefined);
    handleSearch(null);
  };

  const handleChangePage = (event: any, newPage: number) => {
    handleProductsPageChange(newPage);
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t("bundleEditor.newProductDialogTitle")}</DialogTitle>
      </DialogHeader>
      <div>
        <Input
          value={search}
          onChange={e => {
            e.preventDefault();
            let inputValue  = e.target.value;
            setSearch(inputValue);
            handleSearch(inputValue === "" ? null : inputValue);
          }}
          type="text"
          placeholder={t("bundleEditor.productsPlaceholder")}
          className="block w-full rounded-md border-0 mb-2 py-1.5 pl-3 pr-20 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        />
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("bundleEditor.select")}</TableHead>
                <TableHead>{t("bundleEditor.id")}</TableHead>
                <TableHead>{t("bundleEditor.prodName")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {rowProducts.map((product) => (
                <TableRow key={product.productId}>
                  <TableCell>
                    <Checkbox
                      checked={bufferedSelectedProducts.some(
                        (id) => id === product.productId,
                      )}
                      onCheckedChange={(isChecked) =>
                        handleProductSelection(product.productId, !!isChecked)
                      }
                    />
                  </TableCell>
                  <TableCell>{product.productId}</TableCell>
                  <TableCell>{product.name}</TableCell>
                </TableRow>
              ))}
              {!rowProducts.length && (
                <TableRow>
                  <TableCell colSpan={3} className="h-24 text-center">
                    {t("bundleEditor.noResults")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination
          count={productsPageCount}
          size="small"
          onChange={handleChangePage}
        />
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              setSearch(undefined);
              handleSearch(null);
              }}
          >
            {t("bundleEditor.cancelButton")}
          </Button>
        </DialogClose>
        <DialogClose asChild>
          <Button
            type="button"
            variant="default"
            onClick={() => handleConfirm()}
          >
            {t("bundleEditor.confirmSelection")}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
}
