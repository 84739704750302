import { createContext, ReactNode, useContext, useState } from "react";
import { ProductDTO } from "../types/store.types";

interface ProductCartContextProps {
  products: ProductDTO[];
  removeProduct: (productId: number) => void;
  updateProductQuantity: (product: ProductDTO, quantityChange: number) => void;
}

const ProductCartContext = createContext<ProductCartContextProps>({
  products: [],
  removeProduct: () => true,
  updateProductQuantity: () => true,
});

export const useProductCartContext = () => useContext(ProductCartContext);

interface ProductCartProviderProps {
  children: ReactNode;
  initialProducts: ProductDTO[] | undefined;
}

export const ProductCartContextProvider: React.FC<ProductCartProviderProps> = ({
  children,
  initialProducts = [],
}) => {
  const [products, setProducts] = useState<ProductDTO[]>(initialProducts);

  const removeProduct = (productId: number) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.productId !== productId),
    );
  };

  const updateProductQuantity = (
    product: ProductDTO,
    quantityChange: number,
  ) => {
    setProducts((prevProducts) => {
      const existingProduct = prevProducts.find(
        (prevProduct) => prevProduct.productId === product.productId,
      );

      if (existingProduct) {
        const newQuantity = existingProduct.quantity! + quantityChange;

        if (newQuantity <= 0) {
          return prevProducts.filter(
            (prevProduct) => prevProduct.productId !== product.productId,
          );
        } else {
          return prevProducts.map((prevProduct) =>
            prevProduct.productId === product.productId
              ? { ...existingProduct, quantity: newQuantity }
              : prevProduct,
          );
        }
      }
      return [...prevProducts, { ...product, quantity: quantityChange }];
    });
  };

  return (
    <ProductCartContext.Provider
      value={{ products, removeProduct, updateProductQuantity }}
    >
      {children}
    </ProductCartContext.Provider>
  );
};

export default ProductCartContextProvider;
