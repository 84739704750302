import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEs from "./i18n/locales/es/translation.json";
import translationPr from "./i18n/locales/pr/translation.json";
import translationEn from "./i18n/locales/en/translation.json";

import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  es: {
    translation: translationEs,
  },
  pr: {
    translation: translationPr,
  },
  en: {
    translation: translationEn,
  },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "es", // default
});

export default i18next;
