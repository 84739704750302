import { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Loader from "../Loader";
import NavBar from "../NavBar";
import ProductsTable from "./ProductsTable";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { CircleHelp, Group, Link, Plus } from "lucide-react";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { getBundleErrorPage as LinkErrorPage } from "../ErrorHandler/ErrorHandler";
import SelectProductsModal from "./SelectProductsModal";
import { ApiError } from "src/types/errors.types";
import ErrorAlert from "../ErrorHandler/ErrorComponents/ErrorAlert";
import ProductCartContextProvider, {
  useProductCartContext,
} from "../../providers/ProductCartContextProvider";
import { ApiClient } from "src/types/ApiClient";
import { BundleDTO } from "src/types/bundle.types";
import { ProductDTO, StoreDTO } from "src/types/store.types";
import { Checkbox } from "../ui/checkbox";
import Alert from "@mui/material/Alert";
import { Card, CardActionArea, CardContent, Chip, Typography } from "@mui/material";

const PRODUCTS_PAGE_SIZE: number = 30;

export default function BundleEditorPage({
  apiClient,
}: {
  apiClient: ApiClient;
}) {
  const params = useParams();
  const token = useAuthHeader();
  const [showLoader, setShowLoader] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [bundle, setBundle] = useState<BundleDTO>();

  useEffect(() => {
    const fetchLink = async () => {
      if (!params.slug || !token) {
        return;
      }
      setShowLoader(true);
      const { bundle, error } = await apiClient.getBundle(params.slug, token);
      setShowLoader(false);
      if (error || !bundle) {
        setApiError(error!);
        return;
      }
      setBundle(bundle);
    };

    void fetchLink();
  }, [apiClient, params.slug, token]);

  if (apiError) {
    return <LinkErrorPage apiError={apiError!} />;
  }

  return (
    <>
      <NavBar />
      {showLoader ? (
        <Loader />
      ) : (
        <ProductCartContextProvider
          initialProducts={
            bundle && "products" in bundle?.content
              ? bundle?.content?.products
              : []
          }
        >
          <Editor apiClient={apiClient} bundle={bundle} />
        </ProductCartContextProvider>
      )}
    </>
  );
}

function Editor({
  apiClient,
  bundle,
}: {
  apiClient: ApiClient;
  bundle: BundleDTO | undefined;
}) {
  const token = useAuthHeader();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { products: cartProducts } = useProductCartContext();
  const [name, setName] = useState<string>(bundle?.name || "");
  const [productOffset, setProductOffset] = useState<number>(0);
  const [query, setQuery] = useState<string | null>("");
  const [pageCount, setPageCount] = useState<number>(1);
  const [description, setDescription] = useState<string>(
    bundle?.description || "",
  );

  const [productsResponse, setProductsResponse] = useState<ProductDTO[]>([]);
  const [discount, setDiscount] = useState<string>(
    bundle && "products" in bundle?.content
      ? bundle?.content.discount?.toString() || ""
      : "",
  );
  const [discountError, setDiscountError] = useState<boolean>(false);

  const [showLoader, setShowLoader] = useState(false);
  const [withProduct, setWithProduct] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [store, setStore] = useState<StoreDTO | undefined>(undefined);
  const [openWithProductSelector, setOpenWithProductSelector] = useState<boolean>(!bundle?.slug);

  useEffect(() => {
    const fetchStore = async () => {
      if (!token) {
        return;
      }
      setShowLoader(true);
      const { store, error } = await apiClient.getStore(token);
      setShowLoader(false);
      if (error) {
        setApiError(error!);
      }
      setStore(store);
    };

    void fetchStore();
  }, [apiClient, token]);

  const getUnselectedProducts = useCallback(() => {
    return productsResponse.filter((prod) => {
      // we can't use .include because the quantity aspect might vary.
      return (
        cartProducts.length === 0 ||
        cartProducts.every((p) => p.productId !== prod.productId)
      );
    });
  }, [cartProducts, productsResponse]);

  const getProducts = useCallback(
    async (authToken: string) => {
      if (!store) {
        return;
      }
      if (query === "") {
        setShowLoader(true);
      }

      const { products, pagination, error } = await apiClient.getStoreProducts(
        store?.storeId,
        productOffset,
        PRODUCTS_PAGE_SIZE,
        query === "" ? null : query,
        authToken,
      );
      if (error) {
        setApiError(error);
      }
      setShowLoader(false);

      setProductsResponse(products ?? []);
      setPageCount(
        Math.ceil((pagination?.totalCount || 0) / (pagination?.pageSize || 1)),
      );
    },
    [store, productOffset, query, apiClient],
  );

  useEffect(() => {
    if (token) {
      getProducts(token);
    }
  }, [apiClient, token, getProducts]);

  if (showLoader || productsResponse === undefined) {
    // show loader while api calls finish
    return <Loader />;
  }

  if (productsResponse.length === 0 && query === "") {
    return (
      <h2 className="text-xl text-center mt-20">
        {t("bundleEditor.noProducts")}
      </h2>
    );
  }

  if (apiError) {
    return <LinkErrorPage apiError={apiError!} />;
  }

  const handleSubmission = async (event: FormEvent) => {
    event.preventDefault();
    if (!token || !store || !store.storeId) {
      return;
    }
    setShowLoader(true);
    const applicableDiscount = discount.length === 0 ? null : discount;

    if (bundle) {
      const { error } = await apiClient.updateBundle(
        bundle.slug,
        {
          name: name,
          description: description,
          content: {
            discount: applicableDiscount || "0",
            products: cartProducts,
          },
        },
        token,
      );
      if (error) {
        setApiError(error!);
        return;
      }
    } else {
      const { error } = await apiClient.createBundle(
        {
          storeId: store.storeId,
          name: name,
          description: description,
          discount: applicableDiscount || "0",
          products: cartProducts,
          withCreateProduct: withProduct,
        },
        token,
      );
      if (error) {
        setApiError(error!);
        return;
      }
    }
    navigate("/explore");
  };

  const isValidProductBundleProductSet = (products: ProductDTO[]) => {
    //FixMe: When backend ready: return !withProduct || (withProduct && products.filter(p => p.productType === 'MODEL').map(p => p.quantity || 1).reduce((a, b) => a + b, 0) <= 3);
    return (
      !withProduct ||
      (withProduct &&
        products
          .filter((p) => p.productType === "MODEL")
          .map((p) => 1)
          .reduce((a, b) => a + b, 0) <= 3)
    );
  };

  const isValidBundle = () => {
    return (
      !!name &&
      !!description &&
      cartProducts.length > 0 &&
      isValidProductBundleProductSet(cartProducts)
    );
  };

  const handleSetDiscount = (discountString: string) => {
    setDiscountError(false);
    const discount: number = +discountString;
    if (discount < 0 || discount > 100) {
      setDiscountError(true);
      return;
    }
    setDiscount(discountString);
  };

  return (
    <>
      <Dialog open={openWithProductSelector}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("bundleEditor.withProductSelectorTitle")}</DialogTitle>
          </DialogHeader>
          <DialogClose asChild>
            <Card sx={{ margin: "3%", backgroundColor: "#E0E0E0" }}>
              <CardActionArea disabled onClick={() => {setWithProduct(true); setOpenWithProductSelector(false);}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <Group size={26} style={{ marginBottom: '1%' }} /> {t("bundleEditor.productCombo")}
                  </Typography>
                  <Chip label="Coming soon" color="primary" variant="outlined" />
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t("bundleEditor.productComboDescription")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </DialogClose>
          <DialogClose asChild>
            <Card sx={{ margin: "3%" }}>
              <CardActionArea onClick={() => {setWithProduct(false); setOpenWithProductSelector(false);}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <Link size={26} style={{ marginBottom: '1%' }} /> {t("bundleEditor.linkCombo")}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t("bundleEditor.linkComboDescription")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </DialogClose>
          <DialogFooter>
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate("/explore")}
              >
                {t("bundleEditor.cancelButton")}
              </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <form onSubmit={handleSubmission}>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left pt-4">
            {!bundle && !openWithProductSelector && (withProduct ? <Group size={26} style={{ marginBottom: '1%' }} /> : <Link size={26} style={{ marginBottom: '1%' }} />)}
              <h2 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
                {!!bundle
                  ? t("bundleEditor.editBundle")
                  : t("bundleEditor.createBundle") + (openWithProductSelector ? '' : (withProduct ? t("bundleEditor.productCombo") : t("bundleEditor.linkCombo")))}
              </h2>
              <Button variant="link" onClick={() => navigate("/help")}>
                {t("bundleEditor.helpBundle")}
                <CircleHelp className="ml-1" size={16} />
              </Button>
              <div id="formInputs" className="mt-6">
                {withProduct && (
                  <Alert className="mb-2" severity="info">
                    {t(`bundleEditor.withProductDisclaimer`)}
                  </Alert>
                )}
                <div className="flex flex-col my-4 py-2 space-y-2">
                  <label className="font-bold text-xl text-accent">
                    {t("bundleEditor.name")}
                  </label>
                  <input
                    type="text"
                    required
                    placeholder={t("bundleEditor.namePlaceholder")}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col my-4 py-2 space-y-2">
                  <label className="font-bold text-xl text-gray-700">
                    {t("bundleEditor.description")}
                  </label>
                  <input
                    type="text"
                    required
                    placeholder={t("bundleEditor.descriptionPlaceholder")}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="flex flex-col my-4 py-2 space-y-2">
                  <label className="font-bold text-xl text-gray-700">
                    {t("bundleEditor.discount")}
                  </label>
                  {discountError && (
                    <ErrorAlert message={t("bundleEditor.discountError")} />
                  )}
                  <input
                    type="number"
                    step="1"
                    placeholder={t("bundleEditor.discountPlaceholder")}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={discount}
                    onChange={(e) => handleSetDiscount(e.target.value)}
                  />
                </div>
                {withProduct && (
                  <Alert className="mb-2" severity="info">
                    {t(`bundleEditor.withProductVariantsDisclaimer`)}
                  </Alert>
                )}
                {!isValidProductBundleProductSet(cartProducts) && (
                  <Alert severity="error">
                    {t(`bundleEditor.withProductVariantsError`)}
                  </Alert>
                )}
                <div className="flex flex-col my-4 py-2 space-y-2">
                  <div className="flex justify-between">
                    <label className="font-bold text-xl text-gray-700">
                      {t("bundleEditor.selectedProducts")}
                    </label>
                    {withProduct && (
                      <Alert severity="warning">
                        {t(`bundleEditor.withProductQuantityDisclaimer`)}
                      </Alert>
                    )}
                    <Dialog>
                      <DialogTrigger>
                        <Button
                          type="button"
                          variant="secondary"
                          className="flex"
                        >
                          {t("bundleEditor.addProductButton")}
                          <Plus className=" pl-2" />
                        </Button>
                      </DialogTrigger>
                      <SelectProductsModal
                        rowProducts={getUnselectedProducts()}
                        productsPageCount={pageCount}
                        handleSearch={setQuery}
                        handleProductsPageChange={(newPage: number) =>
                          setProductOffset((newPage - 1) * PRODUCTS_PAGE_SIZE)
                        }
                      />
                    </Dialog>
                  </div>

                  <ProductsTable />
                </div>
              </div>
              <div className="mt-10 flex pb-4 items-center justify-center gap-x-6">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => navigate("/explore")}
                >
                  {t("bundleEditor.cancelButton")}
                </Button>
                <Button type="submit" disabled={!isValidBundle()}>
                  {!!bundle
                    ? t("bundleEditor.editButton")
                    : t("bundleEditor.createButton")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
