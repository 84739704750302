import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex flex-col">
      <NavBar />
      <div className="bg-gray-100 flex items-center justify-center">
        <div className="rounded-lg shadow-lg bg-white p-6 space-y-6 border border-gray-200 dark:border-gray-700">
          <div className="mt-200">
            <h1 className="text-center text-3xl font-bold">
              {t(`privacy.title`)}
            </h1>
            <p className="mt-4 mb-4 text-zinc-500 dark:text-zinc-400">
              {t(`privacy.description`)}
            </p>
            <h2 className="text-xl font-bold">{t(`privacy.infoTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.info`)}{" "}
              <a href="https://sendgrid.com/policies/privacy/">
                {t(`privacy.here`)}
              </a>
              .
            </p>
            <h2 className="text-xl font-bold">
              {t(`privacy.protectInfoTitle`)}
            </h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.protectInfo`)}
            </p>
            <h2 className="text-xl font-bold">{t(`privacy.optionTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.option`)}
            </p>
            <h2 className="text-xl font-bold">{t(`privacy.trackingTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.tracking`)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
