import { Button } from "../../ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "../../ui/dialog";
import { useTranslation } from "react-i18next";
import { BundleDTO } from "src/types/bundle.types";

interface ModalProps {
  bundle: BundleDTO;
  severity: "success" | "warning" | "error";
  handler?: (bundle: BundleDTO) => void;
  name: string;
}
export function Modal({ bundle, severity, handler, name }: ModalProps) {
  const { t } = useTranslation();
  const primaryButtonAction = () => {
    handler?.(bundle);
  };

  const getButtonClassName = () => {
    switch (severity) {
      case "success":
        return "outline-primary text-primary hover:bg-primary hover:text-white";
      case "warning":
        return "outline-amber-400 text-amber-400 hover:bg-amber-400 hover:text-white";
      case "error":
        return "outline-red-600 text-red-600 hover:bg-red-700 hover:text-white";
    }
  };

  const getTitleClassName = () => {
    switch (severity) {
      case "success":
        return "outline-primary text-primary";
      case "warning":
        return "outline-amber-400 text-amber-400";
      case "error":
        return "outline-red-600 text-red-600";
    }
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className={`${getTitleClassName()}`}>
          {t(`modal.${name}.title`)}
        </DialogTitle>
      </DialogHeader>
      <div className="flex flex-col mx-auto p-4">
        <p>{t(`modal.${name}.content`)}</p>
        <p className="mt-2">
          <b>{t(`modal.${name}.prompt`)}</b>
        </p>
      </div>
      <DialogFooter className="flex mx-auto space-between mt-4">
        <DialogClose>
          <Button type="button" variant="outline">
            {t(`modal.${name}.secondaryButton`)}
          </Button>
        </DialogClose>
        <Button
          type="button"
          variant="outline"
          className={`flex mx-auto ${getButtonClassName()}`}
          onClick={primaryButtonAction}
        >
          {t(`modal.${name}.primaryButton`)}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
